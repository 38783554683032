import React from "react";

//   This is the our clients Logo section of the about us page

const ClientLogos = ({ data }) => {
	const logoArray = data.wpPage.blocks[0].saveContent;

	return <div dangerouslySetInnerHTML={{ __html: logoArray }} />;
};

export default ClientLogos;
