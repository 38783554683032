import { useStaticQuery, graphql } from "gatsby"

export const useGetAllTeamMembersQuery = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allWpTeamMember {
        edges {
          node {
            id
            title
            content
            uri
            tags {
              nodes {
                name
              }
            }
            featuredImage {
              node {
                gatsbyImage(width: 300)
              }
            }
          }
        }
      }
    }
  `)
  return data
}
