import React from "react";
import TeamMember from "../TeamMember";
import TitleAndDesc from "../../TitleAndDescription";

//  This is the Team memeber Area component
// it sits in the about us page

const TeamMembersArea = ({ teamMembers }) => {
	return (
		<div>
			<TitleAndDesc />
			{teamMembers.map((memeber, idx) => (
				<TeamMember
					key={idx}
					title={memeber.node.title}
					content={memeber.node.content}
					link={memeber.node.uri}
					image={memeber.node.featuredImage.node.gatsbyImage}
				/>
			))}
		</div>
	);
};

export default TeamMembersArea;
