import React from "react"

// This is the Title and desc component

const TitleAndDesc = ({ title, desc }) => (
  <div>
    <h1 dangerouslySetInnerHTML={{ __html: title }} />
    <p dangerouslySetInnerHTML={{ __html: desc }} />
  </div>
)

export default TitleAndDesc
