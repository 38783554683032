import React from "react";
import { Link } from "gatsby";
import TitleAndDesc from "../../TitleAndDescription";

// This is where all the avaliabe jobs are mapped out
// Custom fields are used for a post on the WP side

const JoinTeam = ({ data }) => {
	console.log(data);

	const jobs = data.allWpCareer.edges;

	return (
		<ul>
			{jobs.map((job, idx) => (
				<li key={idx}>
					<TitleAndDesc title={job.node.title} desc={job.node.content} />
					<button>
						<Link to={`${job.node.uri}`}>Read More</Link>
					</button>
				</li>
			))}
		</ul>
	);
};

export default JoinTeam;
