import { useStaticQuery, graphql } from "gatsby";

export const useGetLogoQuery = () => {
	const data = useStaticQuery(graphql`
		query logoQuery {
			wpPage(databaseId: { eq: 216 }) {
				id
				title
				blocks {
					... on WpCoreGalleryBlock {
						saveContent
					}
				}
			}
		}
	`);
	return data;
};
