import React from "react";
import TitleAndDesc from "../../TitleAndDescription";

//   This is the our clients section of the about us page

const ClientQuotes = ({ data }) => {
	const quoteArray = data.wpPage.blocks;

	return (
		<>
			<TitleAndDesc title={data.wpPage.title} desc={""} />
			{quoteArray.map((quote, idx) => {
				return (
					quote.saveContent && (
						<section key={idx} dangerouslySetInnerHTML={{ __html: quote.saveContent }} />
					)
				);
			})}
		</>
	);
};

export default ClientQuotes;
