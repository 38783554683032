import { useStaticQuery, graphql } from "gatsby";

export const useAboutQuery = () => {
	const data = useStaticQuery(graphql`
		query {
			wpPage(databaseId: { eq: 35 }) {
				id
				title
				uri
				content
			}
		}
	`);
	return data;
};
