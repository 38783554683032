import React from "react";
import TeamMembersArea from "../components/About/TeamMemberArea";
import ClientQuotes from "../components/About/OurClients/ClientQuotes";
import ClientLogos from "../components/About/OurClients/ClientLogos";
import JoinTeam from "../components/About/JoinTeam";
import Layout from "../components/Layout";
import TitleAndDesc from "../components/TitleAndDescription";
import { useAboutQuery } from "../hooks/About/useAbout";
import { useGetJoinTeamQuery } from "../hooks/About/useGetJoinTeamQuery";
import { useGetAllTeamMembersQuery } from "../hooks/About/useGetAllTeamMembersQuery";
import { useGetQuoteQuery } from "../hooks/About/useGetQuoteQuery";
import { useGetLogoQuery } from "../hooks/About/useGetLogoQuery";

//   This is the about page
//  All the components under src/components/About are used here

const About = () => {
	const { wpPage } = useAboutQuery();
	console.log(wpPage);

	const teamMembers = useGetAllTeamMembersQuery().allWpTeamMember.edges;
	console.log("all team members", teamMembers);

	const clientQuotesData = useGetQuoteQuery();
	console.log("client info", clientQuotesData);

	const joinTeamData = useGetJoinTeamQuery();
	console.log("join team info", joinTeamData);

	const clientLogoData = useGetLogoQuery();

	return (
		<Layout>
			<TitleAndDesc title={wpPage.title} />
			<br />
			<div dangerouslySetInnerHTML={{ __html: wpPage.content }} />
			<br />
			<TeamMembersArea teamMembers={teamMembers} />
			<JoinTeam data={joinTeamData} />
			<ClientQuotes data={clientQuotesData} />
			<ClientLogos data={clientLogoData} />
		</Layout>
	);
};

export default About;
