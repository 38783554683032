import { useStaticQuery, graphql } from "gatsby";

export const useGetQuoteQuery = () => {
	const data = useStaticQuery(graphql`
		query quoteQuery {
			wpPage(databaseId: { eq: 208 }) {
				id
				title
				blocks {
					... on WpCoreColumnsBlock {
						saveContent
					}
				}
			}
		}
	`);
	return data;
};
