import { useStaticQuery, graphql } from "gatsby";

export const useGetJoinTeamQuery = () => {
	const data = useStaticQuery(graphql`
		{
			allWpCareer {
				edges {
					node {
						id
						uri
						title
						content
					}
				}
			}
		}
	`);
	return data;
};
