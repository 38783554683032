import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const TeamMember = ({ title, content, link, image }) => {
	return (
		<div>
			<h1>
				<Link to={`/about${link}`}>{title}</Link>
			</h1>
			<GatsbyImage image={image} alt={title} />
			<div dangerouslySetInnerHTML={{ __html: content }} />
		</div>
	);
};

export default TeamMember;
